import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  PreloadAllModules,
  provideRouter,
  RouterModule,
  withInMemoryScrolling,
} from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import {
  AlignJustify,
  BookOpen,
  Building2Icon,
  ChartPie,
  Check,
  ChevronDown,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronsDownIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  ChevronsUpIcon,
  Church,
  CircleCheckIcon,
  CircleUser,
  Clipboard,
  ClipboardList,
  Clock1,
  CloudDownloadIcon,
  CloudHail,
  CloudOff,
  CloudUploadIcon,
  Dot,
  FlagIcon,
  Image,
  Landmark,
  LucideAngularModule,
  Mail,
  MapPin,
  MessageCircleMore,
  MousePointerClick,
  MoveLeft,
  MoveRight,
  Navigation,
  PanelTopIcon,
  PencilIcon,
  PlusIcon,
  PowerIcon,
  Rocket,
  RocketIcon,
  Router,
  SearchIcon,
  Settings,
  SettingsIcon,
  ShieldCheck,
  ShieldPlus,
  TicketPercent,
  Trash2Icon,
  Truck,
  TvMinimalPlay,
  UserPlus,
  UserPlusIcon,
  UserRound,
  Users,
  UsersIcon,
  XIcon,
  TriangleAlertIcon,
  HouseIcon,
  AsteriskIcon,
  MonitorCogIcon,
  SquarePenIcon,
  UserIcon,
  Copy,
  Info,
  Timer,
  Clock,
  Calendar,
  AtSignIcon,
  PhoneIcon,
  CalendarDaysIcon,
  BookMinus,
  ChevronUpIcon,
  Ellipsis,
  UserPen,
  NotebookPen,
  ChartPieIcon,
  FilePlusIcon,
  LinkIcon,
  HashIcon,
  Captions,
  History,
  CircleXIcon,
  MessageCircleQuestionIcon,
  CalendarDays,
  FileIcon,
  VideoIcon,
  SquarePlayIcon,
  FileAudio2Icon,
  ImageIcon,
  PresentationIcon,
  ListTodoIcon,
  ChevronRight,
  ChevronLeft,
  Star,
  TriangleAlert,
  Printer,
  MenuIcon,
  SlidersHorizontalIcon,
  StarIcon,
  FilterIcon,
  ChevronUp,
  LockKeyhole,
  AlbumIcon,
  Download,
  GripHorizontal,
  ClockIcon,
  FileQuestionIcon,
  BookOpenCheckIcon,
  Forward,
  PercentIcon,
  Upload,
  ArrowDownToLine,
  MessageSquareQuote,
  SearchXIcon,
  TvMinimalIcon,
  YoutubeIcon,
  LayersIcon,
  FileClockIcon,
  Reply,
  Bell,
  LogOut,
  Phone,
  Lock,
  BookOpenIcon,
  PencilLine,
  Eye,
  SquareUserRoundIcon,
  PrinterIcon,
  BookmarkIcon,
  Pin,
  EllipsisVerticalIcon,
  SendHorizontal,
  X,
  GlobeIcon,
  CodeIcon,
  ListTodo,
  ArrowRight,
  CircleX,
  FileX,
  EyeIcon,
  KeySquareIcon,
  ChartNoAxesColumnIcon,
  KeyRoundIcon,
  EyeOffIcon,
  RotateCcwIcon,
  PanelLeftOpenIcon,
  Building2,
  Building,
  Video,
  PcCase,
} from 'lucide-angular';
import { ToastrModule } from 'ngx-toastr';
import { routes } from './app.routes';
import { RefreshTokenInterceptor } from './common/interceptors/refresh-token-interceptor.service';
import { UnauthorizedInterceptor } from './common/interceptors/unauthorized-interceptor.service';
import { provideQuillConfig } from 'ngx-quill';
import {
  FacebookLoginProvider,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled', // Tự động scroll lên đầu trang
        anchorScrolling: 'enabled', // Tự động scroll đến anchor nếu có
      }),
    ),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('1637261070202540'),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
    provideLottieOptions({
      player: () => player,
    }),
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(
      HttpClientModule,
      ToastrModule.forRoot({
        positionClass: 'toast-top-right',
        timeOut: 2000,
      }),
      RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled', // Giữ vị trí khi back
        anchorScrolling: 'enabled', // Hỗ trợ scroll đến anchor nếu có
        scrollOffset: [0, 0], // Đảm bảo không bị lệch vị trí,
        preloadingStrategy: PreloadAllModules,
      }),
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
        },
      }),
      LucideAngularModule.pick({
        FileX,
        CircleX,
        ChartPieIcon,
        NotebookPen,
        UserPen,
        Ellipsis,
        ChevronUpIcon,
        BookMinus,
        Calendar,
        UserRound,
        MoveLeft,
        SearchIcon,
        UserPlus,
        Copy,
        Users,
        Rocket,
        PlusIcon,
        CloudUploadIcon,
        CloudDownloadIcon,
        PowerIcon,
        PencilIcon,
        Trash2Icon,
        ChevronRightIcon,
        ChevronDownIcon,
        Dot,
        Settings,
        FlagIcon,
        ChevronsRightIcon,
        XIcon,
        ChevronsLeftIcon,
        CircleCheckIcon,
        Building2Icon,
        SettingsIcon,
        PanelTopIcon,
        BookOpen,
        ClipboardList,
        ChartPie,
        AlignJustify,
        MoveRight,
        UsersIcon,
        ChevronsUpIcon,
        ChevronsDownIcon,
        MousePointerClick,
        ChevronDown,
        Check,
        Clipboard,
        UserPlusIcon,
        ShieldCheck,
        ShieldPlus,
        MapPin,
        Landmark,
        Church,
        Navigation,
        Clock1,
        CircleUser,
        CloudHail,
        CloudOff,
        Image,
        Mail,
        MessageCircleMore,
        Router,
        RocketIcon,
        Truck,
        TvMinimalPlay,
        TicketPercent,
        TriangleAlertIcon,
        HouseIcon,
        AsteriskIcon,
        MonitorCogIcon,
        SquarePenIcon,
        UserIcon,
        Info,
        Timer,
        Clock,
        AtSignIcon,
        PhoneIcon,
        CalendarDaysIcon,
        FilePlusIcon,
        HashIcon,
        Captions,
        History,
        CircleXIcon,
        MessageCircleQuestionIcon,
        CalendarDays,
        FileIcon,
        VideoIcon,
        SquarePlayIcon,
        FileAudio2Icon,
        ImageIcon,
        PresentationIcon,
        ListTodoIcon,
        ChevronRight,
        ChevronLeft,
        Star,
        TriangleAlert,
        Printer,
        MenuIcon,
        SlidersHorizontalIcon,
        StarIcon,
        LinkIcon,
        FilterIcon,
        ChevronUp,
        LockKeyhole,
        AlbumIcon,
        Download,
        GripHorizontal,
        ClockIcon,
        FileQuestionIcon,
        BookOpenCheckIcon,
        Forward,
        PercentIcon,
        Upload,
        ArrowDownToLine,
        MessageSquareQuote,
        SearchXIcon,
        TvMinimalIcon,
        YoutubeIcon,
        LayersIcon,
        FileClockIcon,
        Reply,
        Bell,
        Eye,
        LogOut,
        Phone,
        Lock,
        BookOpenIcon,
        PencilLine,
        SquareUserRoundIcon,
        PrinterIcon,
        BookmarkIcon,
        Pin,
        EllipsisVerticalIcon,
        SendHorizontal,
        X,
        GlobeIcon,
        CodeIcon,
        ArrowRight,
        EyeIcon,
        KeySquareIcon,
        ChartNoAxesColumnIcon,
        KeyRoundIcon,
        EyeOffIcon,
        RotateCcwIcon,
        PanelLeftOpenIcon,
        Video,
        Building2,
        Building,
        PcCase,
      }),
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshTokenInterceptor,
      multi: true,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
    provideQuillConfig({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
          [{ direction: 'rtl' }], // text direction

          [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],

          ['clean'], // remove formatting button

          ['link', 'image', 'video'], // link and image, video
        ],
      },
    }),
  ],
};

export function tokenGetter() {
  return localStorage.getItem('token');
}
