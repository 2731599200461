<div *ngIf="ready">
  <app-loading-service></app-loading-service>
  <router-outlet></router-outlet>

  <app-modal-common
    [displayX]="false"
    [clickOutsideClose]="false"
    [id]="idConfirm"
  >
    <ng-template>
      <app-modal-confirm></app-modal-confirm>
    </ng-template>
  </app-modal-common>
</div>
