import { APP_INITIALIZER, Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { NgIf } from '@angular/common';
import { LoadingServiceComponent } from './common/components/loading-service/loading-service.component';
import { MODAL_ID } from './common/components/modal-common/modal-id.const';
import { ModalCommonComponent } from './common/components/modal-common/modal-common.component';
import { ModalConfirmComponent } from './common/components/modal-confirm/modal-confirm.component';
import { ExcelService } from './modules/excel/excel.service';
import { ImageHandler } from 'ngx-quill-upload';
import Quill from 'quill';
Quill.register('modules/imageHandler', ImageHandler);
import { initFlowbite } from 'flowbite';
import { SignalrService } from './signalr.service';
import { SiteInfoService } from './site-info.service';
import { measurementD } from './utils/constance';
import { finalize, Subscription } from 'rxjs';
import { SettingMenuService } from './modules/cms/admin/web-setting/cms-frontend/setting-menu/menu-setting.service';
import { AppService } from './app.service';
import { settingLanding } from './common/constants/configLanding';
// import { SocketService } from './socket.service';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NgIf,
    RouterLink,
    LoadingServiceComponent,
    ModalCommonComponent,
    ModalConfirmComponent,
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ExcelService],
})
export class AppComponent implements OnInit {
  subscription = new Subscription();
  title = 'training-management-system';
  idConfirm = MODAL_ID.COMMON.CONFIRM;
  hubHelloMessage?: string;
  ready = false;

  // private socketService: SocketService
  constructor(
    private signalrService: SignalrService,
    private siteInfoService: SiteInfoService,
    private settingMenuService: SettingMenuService,
    private appService: AppService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    initFlowbite();

    this.appService.currentReadyRender.subscribe(
      (message) => (this.ready = message),
    );

    this.onGetSettingLanding();

    const favicon = localStorage.getItem('favicon');
    if (favicon) {
      this.setFavicon(favicon);
    }

    this.siteInfoService.fetchData();
    this.signalrService.initiateSignalrConnection();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', measurementD, { page_path: event.urlAfterRedirects });
      }
    });
  }

  onGetSettingLanding() {
    const domain = window.location.hostname || 'localhost';
    this.subscription.add(
      this.settingMenuService
        .getSiteNoAuth(domain)
        .pipe(
          finalize(() => {
            this.appService.updateReady();
          }),
        )
        .subscribe((res: any) => {
          this.appService.updateCurrentCSKH(res.data?.settingCSKH);
          this.appService.updateListNew(res.data?.news);
          this.appService.updateConfigMenu(res.data?.configMenu);
          if (res?.data?.cmsFrontEnd) {
            try {
              const data = JSON.parse(res.data.cmsFrontEnd);
              this.appService.updateSettingLanding(data);
              this.changeColorRoot(data?.color?.textMain, data?.color?.subMain);
            } catch (error) {
              console.error('Parser setting landing err!');
            }
          } else {
            this.changeColorRoot(
              settingLanding?.color?.textMain,
              settingLanding?.color?.subMain,
            );
          }
        }),
    );
  }

  changeColorRoot(colorPrimary: string, colorSecondary: string) {
    const root = document.documentElement;
    root.style.setProperty('--color-primary', colorPrimary);
    root.style.setProperty('--color-active', colorPrimary);
    root.style.setProperty('--color-activeHV', colorSecondary);
  }

  setFavicon(newFavicon: string) {
    let favIcon: HTMLLinkElement = document.querySelector('#favIcon');
    favIcon.href = newFavicon;
  }

  // ngOnDestroy(): void {
  //     this.socketService.disconnect();
  // }
}
